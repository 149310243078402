<template>
	<div id="Case">
		<div class="caseone">
			<div class="back" @click="RouTe">
				<van-icon name="revoke" color="#fff" size="13" style="margin-left:20px"/>
			</div>
			<div class="round">{{Second}}/{{Detail.box_num}}回合</div>

			<div class="price">
				<img src="@/assets/images/public/Gold.png" alt="">{{Detail.total_bean}}
			</div>
		</div>
		<div class="box_list">
			<div class="box_item" v-for="(item,index) in game_arena_box" :key="index">
				<img :src="item.cover | ImgFilter" alt="" @click="zhetrue(item)">
				<p><img src="@/assets/images/public/Gold.png" alt="">{{ item.game_bean }}</p>
			
			</div>
		</div>
		<div class="zhe" v-if="flags==true">
			<div class="itemboxlist">
				<span style="fontSize:50px" @click="flags=false">×</span>
				<div class="boxbox">
					<div v-for="(item, index) in zhelist" :key="index" class="itemboxitem" :class="item.lv | LvGuoLv">
						<img :src="item.cover" alt="" class="imgcover"> 
						<p>{{ item.name }}</p>
						<p><img src="@/assets/images/public/Gold.png" alt="" style="width:10px">{{ item.bean }}</p>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default{
		name:'Case',
		props:{
			Detail:{
				type:Object//对战详情
			},
			game_arena_box:{
				type:Array//箱子数量
			},
			Second:{//回合数
				type:Number
			}
		},
		data(){
			return{
				zhelist:[],
				flags:false,
			}
		},
		filters: {
			LvGuoLv(val){
				if (val == '1') {
					return 'lv1'
				} else if (val == '2') {
					return 'lv2'
				} else if (val == '3') {
					return 'lv3'
				} else if (val == '4') {
					return 'lv4'
				}else if (val == '5') {
					return 'lv5'
				}else if (val == '6') {
					return 'lv6'
				}
			},
		},
		methods:{
			RouTe(){
				this.$router.push('/Battle')
			},
			zhetrue(item){
				this.zhelist=item.box_award
				this.flags=true
			}
		}
	}
</script>

<style lang="scss">
.zhe{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color: #000000, $alpha: 0.3);
	z-index: 99;
	.itemboxlist{
		width: 90%;
		height: 500px;
		margin: 100px auto;
		display: flex;
		flex-wrap: wrap;
		background: white;
		background: url('../../../../assets/images/q2/openbox/xiangbac.png') no-repeat center;
		background-size: 100% 100%;
		justify-content: center;
		padding-top: 50px;
		position: relative;
		span{
			position: absolute;
			right: 20px;
			top: 0;
		}
		.boxbox{
			width: 100%;
			height: 490px;
			position: absolute;
			overflow: scroll;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			align-content: flex-start;
			.itemboxitem{
				width: 45%;
				height: 130px;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				color: white;
				&.lv1{
					background-size: 100% 100%;
					border-radius: 5px;
					background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
					background: url('../../../../assets/images/q2/openbox/1.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv2{
					background-size: 100% 100%;
					border-radius: 5px;
					// border: 1px solid rgb(210, 73, 73);
					background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
					background: url('../../../../assets/images/q2/openbox/2.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv3{
					background-size: 100% 100%;
					border-radius: 5px;
					background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,44,83));
					background: url('../../../../assets/images/q2/openbox/3.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv4{
					background-size: 100% 100%;
					border-radius: 5px;
					background: linear-gradient(to top, rgba(255,0,0,0), rgb(57,79,102));
					background: url('../../../../assets/images/q2/openbox/4.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv5{
					background-size: 100% 100%;
					border-radius: 5px;
					background: linear-gradient(to top, rgba(81, 72, 72, 0), rgb(87, 92, 104));
					background: url('../../../../assets/images/q2/openbox/5.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv6{
					background-size: 100% 100%;
					border-radius: 5px;
					background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
					background: url('../../../../assets/images/q2/openbox/1.png') no-repeat center;
					background-size: 100% 100%;
				}
				p{
					width: 80%;
					text-align: center;
				}
				.imgcover{
					width: 70%;
					height: 80px;
				}
				
			}
		}
		
		
	}
}
	#Case{
		height: 100px;
		background: #20222b;
		background-size: 100% 100%;
		border-radius: 6px;
		overflow: hidden;
		// display: flex;
		color: #fff;
		background: url('../../../../assets/images/q2/pkin/topbox.png') no-repeat center;
		background-size: 100% 100%;
		.caseone{
			display: flex;
			flex-wrap: nowrap;
		}
		.back{
			width: 38px;
			height: 22px;
			display: flex;
			justify-content: center;
			align-items: center;
			// padding-bottom: 4px;
			box-sizing: border-box;
		}
		.round{
			width: 54px;
			height: 22px;
			text-align: center;
			line-height: 22px;
			font-size: 10px;
		}
		.box_list{
			flex: 1;
			margin-left: 2px;
			overflow-x: auto;
			white-space: nowrap;
			width: 90%;
			margin: 0 auto;
			.box_item{
				display: inline-block;
				width: 80px;
				height: 50px;
				img{
					max-width: 100%;
					max-height: 100%;
					vertical-align: middle;
				}
				p{
					text-align: center;
					img{
						width: 10px;
						height: 10px;
					}
				}
			}
		}
		
		.price{
			width: 70px;
			height: 22px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 10px;
			color: #fff;
			img{
				width: 10px;
				height: 10px;
				vertical-align: middle;
				margin-right: 2px;
			}
		}
	}
</style>
