<template>
  <div id="Combat">
    <Case
      :Detail="Detail"
      :game_arena_box="game_arena_box"
      :Second="Second"
    ></Case>
    <div class="vs_out">
      <Vsitem
        v-for="(item, index) in PlayerArr"
        :PlayerArr="PlayerArr"
        :ItemData="item"
        :game_arena_box="game_arena_box"
        :game_arena_id="game_arena_id"
        :status="status"
        :seat="index"
        :win_user="win_user"
        :key="index"
        @setSecond="setSecond"
      ></Vsitem>
    </div>
  </div>
</template>

<script>
import Case from "./components/Combat/Case.vue"; //顶部信息栏
import Vsitem from "./components/Combat/Vsitem.vue"; //对战模块
import { ArenaDetail } from "@/network/api.js";
import { mapState } from "vuex";
export default {
  name: "Combat",
  data() {
    return {
      Detail: {}, //详情
      game_arena_box: [], //箱子列表
      status: 0, //进程
      PlayerArr: [], //玩家列表
      game_arena_id: "", //对战id
      Second: 1, //回合数
      win_user: [], //胜利玩家列表(有平局)
    };
  },

  created() {
    this.GetArenaDetail();
  },

  methods: {
    //对战详情
    GetArenaDetail() {
      ArenaDetail(this.$route.query.id).then((res) => {
        // console.log(res.data.data)
        this.game_arena_id = res.data.data.id;
        this.Detail = res.data.data;
        this.game_arena_box = res.data.data.game_arena_box;
        this.whether(res.data.data.status);
        this.operation(res.data.data.win_user_id);
        this.SeatScreening(
          res.data.data.user_num,
          res.data.data.game_arena_player
        );
      });
    },

    //确定座位顺序
    SeatScreening(num, arr) {
      let A_Arr = [];
      for (let i = 0; i < num; i++) {
        A_Arr[i] = null;
      }

      for (let k = 0; k < arr.length; k++) {
        A_Arr[arr[k].seat] = arr[k];
      }
      this.PlayerArr = A_Arr;
    },

    //胜利玩家
    operation(v) {
      if (v != null) {
        this.win_user = v;
      }
    },

    whether(v) {
      if (this.$route.query.type == "2") {
        setTimeout(() => {
          this.Second = this.game_arena_box.length;
          this.status = 2;
        }, 100);
      } else if (this.$route.query.type == "1") {
        setTimeout(() => {
          this.status = 1;
        }, 100);
      } else {
        setTimeout(() => {
          this.status = v - 1;
        }, 100);
      }
    },

    setSecond(v) {
      //回合数
      this.Second = v + 1;
    },
  },

  computed: {
    ...mapState(["join"]),
  },

  watch: {
    join(v) {
      //vuex监听属于本房价刷新数据
      if (v.game_arena_id == this.game_arena_id) {
        this.GetArenaDetail();
      }
    },
  },

  components: {
    Case,
    Vsitem,
  },
};
</script>

<style lang="scss">
#Combat {
  background: url('../../assets/images/q2/pk/bac.png') no-repeat center;
  background-size: 100% 100%;
  // padding: 10px;
  padding-top: 10px;
  box-sizing: border-box;
  .vs_out {
    display: flex;
    margin-top: 10px;
    // background: #20222b;
    border-radius: 4px;
    overflow: hidden;
    padding: 2px;
    box-sizing: border-box;
    position: relative;
    &::before {
      display: block;
      content: "";
      width: 5px;
      height: 140px;
      background: url(../../assets/images/Vs/Left.png) no-repeat center top;
      background-size: 100% 100%;
      left: 0;
      top: 0;
    }
    &::after {
      display: block;
      content: "";
      width: 5px;
      height: 140px;
      background: url(../../assets/images/Vs/Right.png) no-repeat center top;
      background-size: 100% 100%;
      right: 0;
      top: 0;
    }
  }
}
</style>
