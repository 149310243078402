<template>
	<div id="Slidemod">
		<div class="Slide_out" :style="TopSty" :class="TranSition">
			<div class="Slide_item" :style="'width:'+ WiDth +'px; height:' + HeigHt + 'px; padding:'+ PadDing + 'px 0; box-sizing: border-box;'"
			 v-for="(item,index) in GoodArr" :key="index">
				<div class="item_main">
					<div class="Slide_pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
						<img :src="item.cover" alt="">
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Slidemod',

		props: {
			game_arena_box: { //宝箱信息
				type: Array
			},
			ItemData: { //玩家信息(中奖物品)
				type: Object
			},
			
			second:{
				type:Number
			},
			
			can:{//动画开始
				type:Boolean
			}
		},

		data() {
			return {
				Top: -50, //偏移值(初始)
				TranSition:'',//过度动画
				WiDth: 80, //宽度
				HeigHt: 80, //高度
				PadDing: 5, //内边距
				
				GoodArr: [], //处理后物品

			}
		},

		methods: {
			//处理物品(拼接并打乱)
			HandleGood() {
				let A_Arr = []
				for (let i = 0; i < 10; i++) {
					A_Arr = A_Arr.concat(this.game_arena_box[this.second].box_award)
				}

				//打乱
				function randSort(A_Arr) {
					for (let i = 0, len = A_Arr.length; i < len; i++) {
						let rand = parseInt(Math.random() * len)
						let temp = A_Arr[rand]
						A_Arr[rand] = A_Arr[i]
						A_Arr[i] = temp
					}
					return A_Arr
				}
				this.GoodArr = randSort(A_Arr)
				this.Determine()
			},

			//处理中奖
			Determine() {
				this.GoodArr[33] = this.ItemData.game_award[this.second].box_award //奖品挪移
				this.TranSition = 'active' //增加过度动画
				this.Top = -(this.WiDth * 32 + this.WiDth / 2 + this.PadDing * 2) //偏移值
				setTimeout(() => {
					this.sentence()
				}, 6900)
			},
			
			//子传父(是否继续)
			sentence(){
				if(this.second < this.game_arena_box.length - 1){
					this.$emit('setAnimation', true)
				}else{
					this.$emit('setAnimation', false)
				}
				
			}
			
		},

		watch: {
			can(v){//开始动画              
				if(v == true){
					this.TranSition = ''//删除过度动画
					this.Top = -50//复原偏移值
					setTimeout(() => {
						this.HandleGood()//重新触发
					},100)
				}
			}
		},

		computed: {
			//偏移样式
			TopSty() {
				return 'transform: translateY(' + this.Top + 'px)'
			},
		},
	}
</script>

<style lang="scss">
	#Slidemod {
		width: 100%;
		height: 100%;
		overflow: hidden;
		-webkit-mask-image: linear-gradient(180deg, rgba(46, 56, 80, 0.0) 4%, #000 50%, rgba(46, 56, 80, 0.0) 96%);

		.Slide_out {
			width: 100%;
			height: 100%;
			&.active{
				transition: 6s cubic-bezier(0, 0, 0.28, 1);
			}
			.Slide_item {

				margin: 0 auto;

				.item_main {
					width: 100%;
					height: 100%;
					background: rgba($color: #000000, $alpha: .3);
					border-radius: 4px;
					position: relative;
					.Slide_pic {
						width: 100%;
						height: 100%;
						background-position: center;
						background-size: 60%;
						background-repeat: no-repeat;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							max-width: 100%;
							max-height: 100%;
							vertical-align: middle;
						}
					}
				}

			}
		}
	}
</style>
